
import React from 'react';
import style from './About.module.css';
import Border from './Border';
import { Link } from 'react-router-dom';


function About() {
  return (
    <>
      <div className={style.wrapper}>
        <p className={style.about}>В 2016 году у членов РЦНК "Надежда" появилась замечательная идея - создание собственных уникальных самарских шпрухов. Эта творческая концепция стала частью проекта "Kunst, Sport und Spaß , und nicht nur das!" (Искусство, спорт и веселье, и не только!). В процессе осуществления этой задумки, была создана специальная творческая мастерская под названием "Aus Omas Truhe" (Из бабушкиного сундучка).</p>
        <p className={style.about}>В рамках проекта родилось 6 национальных вышивок "шпрух", каждая из которых олицетворяла уникальную часть национальной культуры. Творческий процесс включал разработку схем, подбор материалов и инструментов.</p>
        <p className={style.about}>
        На нашем сайте вы можете <Link to="/embroideries" className={style.link}>скачать</Link> некоторые схемы готовых шпрухов и вышить их самостоятельно.
      </p>
        <p className={style.about}>В 2020 году творческая мастерская "Aus Omas Truhe" обрела своё продолжение. Участники проекта вновь погрузились в разработку и создание своих собственных шпрухов, вдохновившись готовыми работами предыдущего проекта. Результатом творческого процесса стали 10 прекрасных вышивок. Часть из них размещена в «немецком доме» в этнокультурном парке, часть осталась в РЦНК Надежда в г.Самаре, а часть отправилась украшать центры встреч в Самарской области.</p>
      </div>
      <div className={style.border}>
      <Border />
      </div>
    </>
  );
}

export default About;
