import React, { useState, useCallback } from 'react';
import style from './Navbar.module.css';
import Logo from '../../assets/logo.svg';
import '../../variables.css';
import OutlineMenu from '../../assets/icon-menu.svg';
import OutlineClose from '../../assets/icon-close.svg';
import { NavLink } from "react-router-dom";

function Navbar() {
    const [nav, setNav] = useState(false);
    const toggleNav = useCallback(() => {
        setNav(prevNav => !prevNav);
    }, []);
    
    return (
        <header className={style.header}>
            <div className={style.header_wrapper}>
                <div className={style.box}>
                    <div onClick={toggleNav} className={style.mobile_btn} aria-label={nav ? "Close menu" : "Open menu"}>
                        {nav ? <img src={OutlineClose} alt="Close Icon" /> : <img src={OutlineMenu} alt="Menu Icon" />}
                    </div>

                    <div className={style.logo_image}>
                        <NavLink to="/" >
                            <img src={Logo} alt="logo" />
                        </NavLink>
                    </div>

                    <div onClick={toggleNav} className={`${style.overlay} ${nav ? style.overlay_active : ''}`}></div>
                    <ul className={`${style.menu} ${nav ? style.active_mobile : ''}`}>
                        <li>
                            <NavLink onClick={toggleNav} className={({ isActive }) => isActive ? style.active_link : style.menu_item} to="/">
                                Главная
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={toggleNav} className={({ isActive }) => isActive ? style.active_link : style.menu_item} to="/embroideries">
                                Вышивки
                            </NavLink>
                        </li>
                        <li>
                            <NavLink onClick={toggleNav} className={({ isActive }) => isActive ? style.active_link : style.menu_item} to="/about">
                                О проекте
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
};

export default Navbar;
