import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Embroideries from './components/Pages/Embroideries';
import Resources from './components/Pages/Resources';
import EmbroideryDetails from './components/Pages/EmbroideryDetails';
import { Layout } from "./components/Pages/Layout";

const firebaseConfig = {
  apiKey: "AIzaSyBWZUtjCWMS8B6j9uzikfztfy65bSgLTD8",
  authDomain: "spruchaussamara.firebaseapp.com",
  projectId: "spruchaussamara",
  storageBucket: "spruchaussamara.appspot.com",
  messagingSenderId: "825581386116",
  appId: "1:825581386116:web:d0bfdff7432c4b5ab285d1",
  measurementId: "G-44E1YDWESM"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function App() {
  const [embroideries, setEmbroideries] = useState([]);

  useEffect(() => {
    const fetchEmbroideries = async () => {
      const designsCol = collection(db, 'designs');
      const designsSnapshot = await getDocs(designsCol);
      const designsList = designsSnapshot.docs.map(doc => doc.data());
      setEmbroideries(designsList);
    };

    fetchEmbroideries();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route
            path="embroideries"
            element={<Embroideries embroideries={embroideries} />}
          />
          <Route
            path="embroidery/:id"
            element={<EmbroideryDetails embroideries={embroideries} />}
          />
          <Route path="/resources" element={<Resources />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
