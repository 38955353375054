
import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import style from './EmbroideryDetails.module.css'
import { Link } from 'react-router-dom';
import DownloadButton from './DownloadButton';
import Border from './Border';
import ClipLoader from "react-spinners/ClipLoader";

function EmbroideryDetails({ embroideries }) {
  const { id } = useParams();
  const embroidery = embroideries.find(embroidery => embroidery.id === parseInt(id));

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [id]);

  if (!embroidery) {
    return (
      <div className={style.wrapper}>
        <div className={style.loader}>
          <ClipLoader color="grey" aria-label="loading" />
        </div>
      </div>
    );
  }

  const { title, image, translation, description, colors, size } = embroidery;

  const fileBucket = "spruchaussamara.appspot.com"; 
  const filePath = `embroidery${id}.pdf`; 
  const fileDownloadURL = `https://firebasestorage.googleapis.com/v0/b/${fileBucket}/o/${encodeURIComponent(filePath)}?alt=media`;


  return (
    <>
      <div className={style.wrapper}>
        <Link to={`/embroideries`} className={style.arrow_link}>
          <span>&larr;</span> Назад
        </Link>
        <h1 className={style.title}>{title} ({translation})</h1>

        <div className={style.grid_container}>
          <img className={style.embroidery_image} src={require(`../../assets/images/${image}`)} alt="embroidery details" />
          <div className={style.info_container}>
            <p className={style.customText}>
              Скачайте бесплатную схему вышивки крестиком.<br />
              Мы подобрали все необходимые материалы, чтобы вы могли создать свой собственный шпрух
            </p>

            <p className={style.description}>{description}</p>
            <p className={style.options}>Количество цветов: {colors || "не указано"}</p>
            <p className={style.options}>Палитра: DMC</p>
            <p className={style.options}>Размер вышивки: {size ? `${size} клеточек` : "не указано"}</p>
            <p className={style.options}>Формат файла: PDF</p>
            <DownloadButton href={fileDownloadURL} className={style.btn_download} />
          </div>
        </div>
      </div>
      <Border />
    </>
  );
}

export default EmbroideryDetails;