import React from 'react';
import style from './Emroideries.module.css';
import EmbroideryCard from '../cards/EmbroideryCard';
import Border from './Border';

function Embroideries({ embroideries }) {
  return (
    <>
      <div className={style.wrapper}>
        <h1 className={style.title}>Вышивки - Spruch</h1>
        <p className={style.customText}>
          Скачайте бесплатную схему вышивки крестиком.<br />
          Мы подобрали все необходимые материалы, чтобы вы могли создать свой собственный шпрух
        </p>
        <div className={style.cards_container}>
          {embroideries.map((embroidery) => (
            <EmbroideryCard key={embroidery.id} embroidery={embroidery} />
          ))}
        </div>
      </div>
      <Border />
    </>
  );
}

export default Embroideries;
