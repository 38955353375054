import React, { useState } from 'react';
import styles from './styles.module.css';

export const CookieModal = () => {
    const [isOpen, setIsOpen] = useState(true);

    const handleClose = () => {
        setIsOpen(false);
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>
                <p className={styles.notifikation}>Мы используем только обязательные cookie, необходимые для работы сайта.</p>
                <button className={styles.button} onClick={handleClose}>Ok</button>
            </div>
        </div>
    );
};


