import React, { useLayoutEffect } from 'react';
import style from './Border.module.css';

function Border() {
  useLayoutEffect(() => {
    const borderElements = document.getElementsByClassName(style.border);
    const lastBorderElement = borderElements[borderElements.length - 1];
  
    if (lastBorderElement) {
      lastBorderElement.classList.add(style.last_border);
    }
  }, []);
  
  return <div className={style.border}></div>;
}

export default Border;
