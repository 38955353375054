import React from 'react';
import style from './EmbroideryCard.module.css';
import { Link } from 'react-router-dom';

function EmbroideryCard({ embroidery }) {

  return (
    <div className={style.card}>
      <Link to={`/embroidery/${embroidery.id}`} style={{ margin: '0 auto', marginBottom: '10px' }}>
        <img src={require(`../../assets/images/${embroidery.image}`)} alt='embroidery' className={style.card_image} />
      </Link>
      <h2 className={style.card_title}>{embroidery.translation}</h2>
      <Link to={`/embroidery/${embroidery.id}`}>
        Подробнее <span>&rarr;</span>
      </Link>
    </div>
  );
}

export default EmbroideryCard;
