import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import style from './Layout.module.css';
import Modal from '../modal/component';
import {CookieModal} from "../modal/CookieModal/component";

const Layout = () => {
    return (
        <>
            <Navbar />
            <main className={style.main}>
            <Modal >
                <CookieModal />
            </Modal>
                <Outlet />
            </main>
            <Footer />
        </>
    )
}

export { Layout };