// Home.jsx
import React from 'react';
import { Helmet } from 'react-helmet';
import style from './Home.module.css';
import HouseImage from '../../assets/images/house.jpg';
import HoffnungImage from '../../assets/images/hoffnung_p.jpg';
import BoatImage from '../../assets/images/boat.jpg';
import MeinWelt from '../../assets/images/haus_p.jpg';
import GottImage from '../../assets/images/gott_p.jpg';
import IchImage from '../../assets/images/ichaber.jpg';
import HomeCard from '../cards/HomeCard';
import Border from './Border';


function Home() {
    return (
        <div>
            <Helmet>
                <title>Шпрух из Самары - главная</title>
                <meta name='description' content='Скачать бесплатные схемы вышивок-шпрухов на немецком языке из Самары. Шпрухи - это короткие пословицы или поговорки, которые отражают народную мудрость.' /> 
                <meta name='keywords' content='шпрух из Самары, spruch aus samara, шпрухи росских немцев, шпрухи, вышивка крестом, схемы вышивки, бесплатные схемы вышивки крестиком, российские немцы, Самара, самарские немцы' />
                <meta name='author' content='RZDK Hoffnung' />
                <meta property='og:title' content='Шпрух из Самары - главная' />
                <meta property='og:description' content='Скачать бесплатные схемы вышивок-шпрухов на немецком языке из Самары. Шпрухи - это короткие пословицы или поговорки, которые отражают народную мудрость.' />
                <meta property='og:image' content='https://spruchaussamara.web.app/static/media/boat.0953c0c9abb39424612d.jpg' />
                <meta name='twitter:title' content='Шпрух из Самары - главная' />
                <meta name='twitter:description' content='Скачать бесплатные схемы вышивок-шпрухов на немецком языке из Самары. Шпрухи - это короткие пословицы или поговорки, которые отражают народную мудрость.' />
            </Helmet>
            <div className={style.wrapper}>

                <div className={style.home_intro}>
                    <div className={style.intro_container}>
                        <h1 className={style.intro_title}>
                            Шпрухи из Самары - <span className={style.additional_text}>народная мудрость, созданная самарскими мастерами</span>
                        </h1>
                        <p className={style.intro}>Шпрух (в переводе с немецкого) – это краткое изречение на немецком языке (народная мудрость, высказывание, цитата из Библии), художественно оформленное в виде картины и помещенное в рамку.</p>
                    </div>
                    <img src={HouseImage} className={style.intro_image} alt="house embroidery"></img>
                </div>
           

            <Border />

           
                <div className={style.home_info}>
                    <img src={BoatImage} className={style.info_image} alt="boat embroidery"></img>
                    <h2 className={style.info_title}>Что такое Шпрух?</h2>
                    <p className={style.info}>Панно-шпрух (нем. «spruch» — «изречение») — красочная, чаще всего вышитая картина с пословицами и поговорками, изречениями из Библии или благопожеланиями, часто встречающаяся в немецких домах России как настенное украшение.
                    </p>
                    <br></br>
                    <p className={style.info}>
                        Изготавливали шпрухи местные художники по заказу будущих владельцев. Текст изречения выполняли в готическом стиле, вокруг него вышивали цветочный орнамент или пейзаж. Готовые шпрухи вешали в рамке на стене в центральной части комнаты или над кроватью.
                    </p>
                    <br></br>
                    <p className={style.info}>
                        Заключая в себе жизненную мудрость, шпрухи стали неотъемлемым элементом традиционной культуры российских немцев. Их дарили на свадьбу или праздник, передавали из поколения в поколение.
                    </p>
                </div>
      
            <Border />
       
                <h2 className={style.home_title}>Наши Шпрухи:</h2>
                <p className={style.info}>
                    В центрах немецкой культуры Самарской области находятся шпрухи, которые члены РЦНК Надежда делают своими руками, собирают, бережно хранят и реставрируют.
                </p>
                <div className={style.home_cards}>
                    <HomeCard
                        image={HoffnungImage}
                        title="Hoffnung ist eine Ewige Kerze"
                        text="Надежда - это вечная свеча. Шпрух вышит членами РЦНК Надежда, хранится в городе Самара в центре немецкой культуры"
                    />
                    <HomeCard
                        image={MeinWelt}
                        title="Mein Haus ist meine Welt, darin es mir gefällt"
                        text="Мой дом - мой мир, в котором мне нравится.
                        Шпрух вышит членами РЦНК Надежда, хранится в городе Самара в центре немецкой культуры"
                    />
                    <HomeCard
                        image={GottImage}
                        title="Gott segne dieses Haus"
                        text="Божье благословение этому дому.
                        Шпрух вышит членами РЦНК Надежда, хранится в городе Самара в центре немецкой культуры"
                    />

                </div>
       

            <Border />
          
                <h2 className={style.home_title}>Какие бывают шпрухи:</h2>
                <div className={style.description}>
                    <div className={style.description_texts}>
                        <p className={style.description_text}>Шпрухи - это традиционный вид декоративно-прикладного искусства российских немцев. Шпрухи могли носить как религиозный, так и светский, сатирический характер. Со временем технология изготовления шпрухов менялась. Чаще всего это была вышивка, заключённая в рамку. С конца 19 века наибольшее распространение получила техника нанесения рисунка красками на стекло.
                        </p>
                        <br></br>
                        <p className={style.description_text}>
                            Шпрухи - это неотъемлемый элемент традиционной культуры российских немцев. Их часто делали на заказ и дарили в качестве подарка на свадьбу или на праздник, передавали из поколения в поколение.
                        </p>
                        <br></br>
                        <p className={style.description_text}>
                            Самыми популярными вариантами оформления текста, выполненного в готическом стиле, были цветочные орнаменты и пейзажи. Готовые шпрухи вешали в рамке на стене в центральной части комнаты или над кроватью.
                        </p>
                        <br></br>
                        <p className={style.description_text}>
                            Шпрухи - это не только элемент декора, но и своеобразный источник информации о жизни и быте российских немцев. На шпрухах можно увидеть изображения различных предметов быта, одежды, а также сцены из жизни.
                        </p>
                    </div>
                    <div className={style.description_card}>
                        <img src={IchImage} className={style.card_image} alt="shpruch"></img>
                        <h3 className={style.card_title}>"Ich aber und mein Haus, wolle dem Herrn dienen"</h3>
                        <p className={style.card_text}>Я и мой дом будем служить Господу.
                            Шпрух выполнен в технике нанесения рисунка на стекло, хранится в городе Самара в центре немецкой культуры
                        </p>
                    </div>
                </div>
            </div>

            <Border />
        </div>
    );
}

export default Home;
