import React from 'react';
import style from './HomeCard.module.css';

function HomeCard({ image, title, text }) {
  return (
    <div className={style.home_card}>
      <div style={{ margin: '0 auto', marginBottom: '10px' }}>
        <img src={image} className={style.card_image} alt="embroidery" />
      </div>
      <h3 className={style.card_title}>{title}</h3>
      <p className={style.card_text}>{text}</p>

    </div>
  );
}

export default HomeCard;
