import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export default function Modal({ children }) {
  const [mounted, setMounted] = useState(false);
  const modalContainer = useRef(null);

  useEffect(() => {
    setMounted(true);
    modalContainer.current = document.getElementById("root");
  }, []);

  if (!mounted || !modalContainer.current) {
    return null;
  }

  return createPortal(<>{children}</>, modalContainer.current);
}
