import React from "react";

const DownloadButton = ({ href, className }) => {
  return (
    <a href={href} download className={className} target="_blank" rel="noopener noreferrer">
      Скачать
    </a>
  );
};

export default DownloadButton;
