import React from 'react';
import style from './Footer.module.css';

function Footer() {

    return ( 
        <footer className={style.footer}>
          <h3 className={style.footer_text}>&copy; RZDK Hoffnung 2023</h3>
        </footer>
    );

}

export default Footer;